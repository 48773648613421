<template>
  <v-app>
    <pages-core-view />
  </v-app>
</template>

<script>
  export default {
    components: {
      PagesCoreView: () => import('./components/core/View'),
    },
    async created () {
      this.$vuetify.theme.dark = true
    },

    beforeDestroy () {
      this.$vuetify.theme.dark = false
    },
  }
</script>
